import { MediaModel } from "src/models";
import {
  marketplaceDimensionsValues,
  photoResolutionValues,
  turnTableDirectionControl,
  turnTableDegreesControl,
  turnTableSpeedControl,
  storageSpaceUnit,
} from "./camera.constants";
import { Webgl2New } from "@gemlightbox/core-kit";

export type ConnectionStatusKeys = "connected" | "notConnected" | "pending" | "testFrameRateing";

export type SupportedAdjustments =
  | "u_brightness"
  | "u_contrast"
  | "u_saturation"
  | "u_sharpness"
  | "u_levelsTone"
  | "u_hue"
  | "u_levelsBrightness"
  | "u_temperature";

export type AdjustmentType = {
  name: string;
  uniform: SupportedAdjustments;
  min: number;
  max: number;
  step: number;
  hidden?: boolean;
};

export type FocusModeType = "continuous" | "manual";

export type FocusConstraintType = {
  focusMode: FocusModeType;
  focusDistance: Omit<AdjustmentType, "label" | "uniform" | "name">;
};

export type CaptureModeType = "photo" | "video";

export type CameraMediaType = {
  element: HTMLImageElement | HTMLVideoElement;
  previewSrc: string;
  blinkId?: MediaModel["id"];
  aiHandling?: "AiRetouch" | "AiRemoveDust" | "AiDescription" | "AiBackgroundRemoval";
  uuId?: string;
  processd: "background" | "retouch" | "original";
  blobData?: Blob;
};

export type CameraResolutionType =
  | keyof typeof photoResolutionValues
  | keyof typeof marketplaceDimensionsValues;

export type TurnTableDirectionControlKeys = keyof typeof turnTableDirectionControl;
export type TurnTableDegreesControlKeys = keyof typeof turnTableDegreesControl;
export type TurnTableSpeedControlKeys = keyof typeof turnTableSpeedControl;

export type FilterItemKeys = 0 | 1 | 2 | 3 | 4 | 5;
export type FilterItemType = {
  label: string;
  value?: boolean;
  hidden: boolean;
  defalut: boolean;
};
export type GemAIMapKeys = "description" | "background" | "retouch";
export type GemAIMapType = Record<GemAIMapKeys, Record<FilterItemKeys, FilterItemType>>;

export type StorageSpaceUnitKeys = keyof typeof storageSpaceUnit;
export type LocalSaveSettingsType = {
  isLocalSaveMode: boolean;
  miniStorageSpace: number;
  storageSpaceUnit: StorageSpaceUnitKeys;
  folderPath?: string;
  useCustom?: boolean;
};
export enum FilterMode {
  GemLightbox = 0,
  GemLightboxPro,
  Eclipse,
  Macro,
  GemStack,
  GemCamOnly,
}

export enum FilterScenarioMode {
  Default = 0,
  WhiteGold,
  LooseDiamond,
  NaturalHue,
}

export type FilterModeKeys = keyof typeof FilterMode | keyof typeof FilterScenarioMode;
export type BaseCameraSettings = {
  filterValue: FilterMode;
  filterScenarioValue: FilterScenarioMode;
  gemAIData: GemAIMapType;
  videoAngle: TurnTableDegreesControlKeys;
  videoSpeed: TurnTableSpeedControlKeys;
  openEclipse: boolean;
  cameraFilters: Partial<Record<FilterModeKeys, Webgl2New.ImageAdjustRectFilters>>;
};
export type CameraSettingsType = {
  filterValue: FilterMode;
} & Partial<Omit<BaseCameraSettings, "filterValue">>;

export enum AspectRatiosType {
  One2One = "1:1",
  Four2Three = "4:3",
  Sixteen2Nine = "16:9",
  Four2Five = "4:5",
  Nine2Sixteen = "9:16",
  OnePointNineOne2One = "1.91:1",
}
